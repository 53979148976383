import { combineReducers } from 'redux'
import userReducer, { initialState as userInitialState } from './user/reducer'
import settingsReducer from './settings/reducer'
import accountsReducer, { initialState as accountsInitialState } from './accounts/reducer'
import transactionsReducer from './transactions/reducer'
import exchangeRatesReducer from './exchangeRates/reducer'
import budgetReducer from './budget/reducer'

const appReducer = combineReducers({
  user: userReducer,
  settings: settingsReducer,
  accounts: accountsReducer,
  transactions: transactionsReducer,
  exchangeRates: exchangeRatesReducer,
  budget: budgetReducer
})

export default (state, action) => {
  switch (action.type) {
    case 'LOGOUT':
      return {
        user: userInitialState,
        accounts: accountsInitialState
      }
    default:
      return appReducer(state, action)
  }
}
