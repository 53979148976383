export default {
  LOAD_TRANSACTIONS: 'LOAD_TRANSACTIONS',
  CREATE_TRANSACTION: 'CREATE_TRANSACTION',
  UPDATE_TRANSACTION: 'UPDATE_TRANSACTION',
  UPDATE_TRANSACTIONS: 'UPDATE_TRANSACTIONS',
  UPATE_TRANSACTIONS_WHERE: 'UPATE_TRANSACTIONS_WHERE',
  ADD_TRANSACTIONS: 'ADD_TRANSACTIONS',
  DELETE_TRANSACTIONS: 'DELETE_TRANSACTIONS',
  APPLY_RULE: 'APPLY_RULE'
}
