import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const useStyles = makeStyles(() => ({
  header: {
    fontSize: '1.1rem',
    fontWeight: 'bold'
  },
  content: {
    textAlign: 'left',
    fontSize: 16
  }
}))


export const Faqs = () => {
  const classes = useStyles()

  const faqs = [
    [
      'How much does Entaxy cost?',
      <>
        Entaxy is a free platform. So you might be wondering how we make the money we
        need to support this platform. We plan on providing services designed for accountants.
      </>
    ], [
      'What does “Entaxy” mean?',
      <>
        <strong>Entaxy (noun) uncountable</strong>: the degree of internal disorganization is
        &nbsp;<em>entropy</em> and the level of internal organization is <em> entaxy</em>.
      </>
    ], [
      'Why did you create another personal finance app?',
      <>
        We strongly believe that everyone should be able to manage their finances without
        giving their data away. Entaxy provides an alternative to creating your own spredsheet.
        The existing personal finance app market forces users to entrust their data to the
        company managing the app and also often to 3rd party providers that offer to collect
        your bank transactions automatically. That’s a convenience that comes at a cost we are not
        willing to pay.
      </>
    ], [
      'How do I get started with Entaxy?',
      <>
        Getting started with Entaxy is easy.
        Sign in using Blockstack or enter as a Guest User.
        Once you’ve done that, you’re all set to create your accounts, upload
        your statements and start tracking your spending!
      </>
    ], [
      'What is Blockstack?',
      <>
        Blockstack is a decentralized computing network and app ecosystem that puts
        users in control of their identity and data.
        <br /><br />You start by creating an identity with Blockstack, if you don’t have one already.
        Your data will be connected to this identity and under your control. From this point on,
        any blockstack app you use will have to get your permission to access your data, including Entaxy.
        <br /><br />Entaxy not only doesn’t control your data but also doesn’t know where it is stored.
      </>
    ], [
      'Where is my data stored?',
      <>
        When you use Blockstack, your data and data storage is tied to your identity.
        Your data storage is on a server that is running the Gaia Storage System,
        which keeps it encrypted.
        <br />As a guest user, your data will be stored locally on your computer.
      </>
    ], [
      'How is my data protected?',
      `
        When you use an Entaxy, you give the application permission to
        read and write from your data store. Entaxy needs your permission to
        access the data. We do not save your data to a server, ensuring
        that it is not collected by hackers.
      `
    ], [
      'How do I delete my data?',
      `
        You can delete the data from your Entaxy account by clicking on your profile
        name in the top right, select ‘Settings ’and click ‘Reset - Delete All Your Data’.
      `
    ]
  ]

  return (
    <div className={classes.root}>
      {faqs.map((faq) => (
        <ExpansionPanel key={faq[0]}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
          >
            <Typography className={classes.header}>
              {faq[0]}
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography className={classes.content}>{faq[1]}</Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      ))}
    </div>
  )
}

export default Faqs
