/* eslint-disable no-plusplus */
import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@mdi/react'
import { mdiArrowExpandHorizontal, mdiArrowCollapseHorizontal } from '@mdi/js'
import { format } from 'date-fns'
import { currencyFormatter } from '../../util/stringFormatter'
import PopupDateRangePicker from '../../common/PopupDateRangePicker'
import useDateRange from '../../util/useDateRange'
import { assetAccounts } from '../../store/accounts/reducer'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(4)
  },
  budgetChart: {
    padding: theme.spacing(2),
    height: 400,
    minWidth: 200
  },
  pageHeader: {
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between'
  },
  filters: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  filterLabel: {
    paddingTop: 20,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: theme.palette.grey[700]
  },
  filterMonthSelect: {
    minWidth: 100,
    marginRight: theme.spacing(1)
  },
  tableHeader: {
    backgroundColor: theme.palette.grey[200]
  },
  accountGroup: {
    paddingTop: theme.spacing(1) / 2,
    paddingBottom: theme.spacing(1) / 2,
    textTransform: 'uppercase',
    fontSize: 11,
    fontWeight: 'bold',
    backgroundColor: theme.palette.grey[100]
  },
  indentedCell: {
    paddingLeft: theme.spacing(4)
  },
  total: {
    backgroundColor: theme.palette.grey[200],
    fontWeight: 'bold'
  }
}))

const TrialBalance = () => {
  const classes = useStyles()
  const {
    accounts,
    transactions,
    budget,
    formatCurrency
  } = useSelector((state) => ({
    accounts: state.accounts.byId,
    transactions: state.transactions.list.sort((a, b) => a.createdAt - b.createdAt),
    budget: state.budget,
    formatCurrency: (value) => currencyFormatter(state.settings.locale, state.settings.currency)(value)
  }))
  const [moreDetail, setMoreDetail] = useState(true)

  const [dateRange, handleSelectDate] = useDateRange(transactions)
  const { startDate, endDate } = dateRange
  const startTimestamp = startDate.getTime()
  const endTimestamp = endDate.getTime()

  const handleDetailToggle = () => (
    setMoreDetail((prevState) => !prevState)
  )

  const balances = {}
  // Initialize account balances
  Object.values(accounts).forEach((account) => {
    const {
      id,
      accountType,
      openingBalance,
      openingBalanceDate
    } = account
    if (openingBalanceDate <= endTimestamp) {
      balances[id] = {
        accountType,
        openingBalance,
        credit: 0,
        debit: 0,
        closingBalance: openingBalance,
        name: [account.institution, account.name].join(' - ')
      }
    }
  })

  // Add transactions to account balances
  for (let t = 0; t < transactions.length; t++) {
    const {
      createdAt,
      accountId,
      amount: { localCurrency: amount },
      transferAccountId
    } = transactions[t]
    // eslint-disable-next-line prefer-destructuring
    let categoryId = transactions[t].categoryId
    const { openingBalanceDate } = accounts[accountId]
    if (createdAt >= openingBalanceDate && accountId in balances && createdAt <= endTimestamp) {
      balances[accountId].closingBalance += amount
      if (createdAt < startTimestamp) {
        balances[accountId].openingBalance += amount
      } else {
        if (amount < 0) balances[accountId].credit += Math.abs(amount)
        if (amount > 0) balances[accountId].debit += Math.abs(amount)
      }
      if (createdAt >= startTimestamp && (categoryId || !transferAccountId)) {
        let name
        if (categoryId) {
          name = budget.categoriesById[categoryId].name
        } else {
          categoryId = 'Uncategorized'
          name = 'Uncategorized'
        }
        if (!(categoryId in balances)) {
          balances[categoryId] = {
            openingBalance: 0,
            credit: 0,
            debit: 0,
            closingBalance: 0,
            name
          }
        }
        balances[categoryId].closingBalance -= amount
        if (amount > 0) balances[categoryId].credit += Math.abs(amount)
        if (amount < 0) balances[categoryId].debit += Math.abs(amount)
      }
    }
  }

  const assetAccountTypes = Object.keys(assetAccounts)
  const data = {
    Assets: {},
    Liabilities: {},
    Equity: {
      Networth: {
        name: 'Networth',
        openingBalance: 0,
        credit: 0,
        debit: 0,
        closingBalance: 0
      },
      Income: {
        name: 'Current period income',
        openingBalance: 0,
        credit: 0,
        debit: 0,
        closingBalance: 0
      }
    },
    Revenue: {},
    Expenses: {},
    Uncategorized: {}
  }
  Object.keys(balances).forEach((id) => {
    let accountGroup
    if ('accountType' in balances[id]) {
      accountGroup = assetAccountTypes.includes(balances[id].accountType) ? 'Assets' : 'Liabilities'
      data[accountGroup][id] = balances[id]
      data.Equity.Networth.openingBalance -= balances[id].openingBalance
      data.Equity.Networth.closingBalance -= balances[id].closingBalance
    } else {
      if (id === 'Uncategorized') {
        accountGroup = id
      } else {
        accountGroup = balances[id].closingBalance < 0 ? 'Revenue' : 'Expenses'
      }
      data[accountGroup][id] = balances[id]
      data.Equity.Networth.debit += Math.abs(balances[id].debit)
      data.Equity.Networth.credit += Math.abs(balances[id].credit)
    }
  })
  data.Equity.Income.debit = data.Equity.Networth.credit
  data.Equity.Income.credit = data.Equity.Networth.debit
  data.Equity.Income.closingBalance = data.Equity.Income.debit - data.Equity.Income.credit

  const total = {
    openingBalance: 0,
    debit: 0,
    credit: 0,
    closingBalance: 0,
    splitClosingBalance: { debit: 0, credit: 0 }
  }
  return (
    <Container className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} className={classes.pageHeader}>
          <Typography variant="h4">Trial Balance</Typography>
          <IconButton aria-label="More detail" onClick={handleDetailToggle}>
            <Icon
              path={moreDetail ? mdiArrowCollapseHorizontal : mdiArrowExpandHorizontal}
              size={1}
              className={classes.importButton}
            />
          </IconButton>
          <div className={classes.filters}>
            <PopupDateRangePicker
              ranges={[dateRange]}
              onChange={handleSelectDate}
              minDate={dateRange.minDate}
              maxDate={dateRange.maxDate}
            >
              {format(dateRange.startDate, 'MMM dd, yyyy')} -&nbsp;
              {format(dateRange.endDate, 'MMM dd, yyyy')}
            </PopupDateRangePicker>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Table size="small">
            <TableHead>
              <TableRow className={classes.tableHeader}>
                <TableCell>Account</TableCell>
                { moreDetail && <TableCell align="right">Opening Balance</TableCell>}
                <TableCell align="right">Debit</TableCell>
                <TableCell align="right">Credit</TableCell>
                { moreDetail && <TableCell align="right">Closing Balance</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(data).map((accountGroup) => {
                return (
                  <React.Fragment key={accountGroup}>
                    <TableRow>
                      <TableCell colSpan={moreDetail ? 5 : 3} className={classes.accountGroup}>
                        {accountGroup}
                      </TableCell>
                    </TableRow>
                    {Object.keys(data[accountGroup]).map((entry) => {
                      let debit = 0
                      let credit = 0
                      total.openingBalance += data[accountGroup][entry].openingBalance
                      total.debit += data[accountGroup][entry].debit
                      total.credit += data[accountGroup][entry].credit
                      total.closingBalance += data[accountGroup][entry].closingBalance
                      if (data[accountGroup][entry].closingBalance > 0) {
                        debit = data[accountGroup][entry].closingBalance
                        total.splitClosingBalance.debit += debit
                      } else {
                        credit = Math.abs(data[accountGroup][entry].closingBalance)
                        total.splitClosingBalance.credit += credit
                      }
                      return (
                        <TableRow key={entry}>
                          <TableCell className={classes.indentedCell}>{data[accountGroup][entry].name}</TableCell>
                          { !moreDetail && (
                            <>
                              <TableCell align="right">{formatCurrency(debit)}</TableCell>
                              <TableCell align="right">{formatCurrency(credit)}</TableCell>
                            </>
                          )}
                          { moreDetail && (
                            <>
                              <TableCell align="right">
                                {formatCurrency(data[accountGroup][entry].openingBalance)}
                              </TableCell>
                              <TableCell align="right">{formatCurrency(data[accountGroup][entry].debit)}</TableCell>
                              <TableCell align="right">{formatCurrency(data[accountGroup][entry].credit)}</TableCell>
                              <TableCell align="right">
                                {formatCurrency(data[accountGroup][entry].closingBalance)}
                              </TableCell>
                            </>
                          )}
                        </TableRow>
                      )
                    })}
                  </React.Fragment>
                )
              })}
            </TableBody>
            <TableHead>
              <TableRow className={classes.total}>
                <TableCell>Total</TableCell>
                { !moreDetail && (
                  <>
                    <TableCell align="right">{formatCurrency(total.splitClosingBalance.debit) }</TableCell>
                    <TableCell align="right">{formatCurrency(total.splitClosingBalance.credit) }</TableCell>
                  </>
                )}
                { moreDetail && (
                  <>
                    <TableCell align="right">{formatCurrency(total.openingBalance)}</TableCell>
                    <TableCell align="right">{formatCurrency(total.debit)}</TableCell>
                    <TableCell align="right">{formatCurrency(total.credit)}</TableCell>
                    <TableCell align="right">
                      {formatCurrency(
                        total.closingBalance.toFixed(2) === '-0.00' ? 0 : total.closingBalance.toFixed(2)
                      )}
                    </TableCell>
                  </>
                )}
              </TableRow>
            </TableHead>
          </Table>
        </Grid>
      </Grid>
    </Container>
  )
}

export default TrialBalance
