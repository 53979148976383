const userWhitelist = [
  'jackneto.id',
  'brianla1.id.blockstack',
  'syntropy.id.blockstack',
  'entaxy_user.id.blockstack',
  'entaxyuser.id.blockstack',
  'odderprod.id.blockstack'
]

const features = {
  showFaqs: false,
  showTrialBalance: false
}

export { features, userWhitelist }
