export default {
  LOAD_BUDGET: 'LOAD_BUDGET',
  CREATE_CATEGORY: 'CREATE_CATEGORY',
  UPDATE_CATEGORY: 'UPDATE_CATEGORY',
  DELETE_CATEGORY: 'DELETE_CATEGORY',
  CREATE_RULE: 'CREATE_RULE',
  UPDATE_RULE: 'UPDATE_RULE',
  DELETE_RULE: 'DELETE_RULE',
  COUNT_RULE_USAGE: 'COUNT_RULE_USAGE',
  RESET_COLOURS: 'RESET_COLOURS'
}
