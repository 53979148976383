import migration0 from './0'
import migration1 from './1'
import migration2 from './2'
import migration3 from './3'

export default {
  0: migration0,
  1: migration1,
  2: migration2,
  3: migration3
}
