import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Link as RouterLink } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import grey from '@material-ui/core/colors/grey'
import logoImg from '../../common/Logo/logo.png'
import image5 from './image5.png'
import image6 from './image6.png'
import image7 from './image7.png'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    padding: '20px',
    flexGrow: 1
  },
  logoWrap: {
    background: grey[100],
    padding: theme.spacing(4),
    paddingBottom: theme.spacing(1)
  },
  logo: {
    font: 'bold 24px var(--font-garden-grove)',
    color: 'black',
    background: `url(${logoImg}) no-repeat left center`,
    backgroundSize: 30,
    paddingLeft: 40,
    display: 'inline-block'
  },
  logoTtagline: {
    font: 'italic 11px var(--font-garden-grove)',
    color: grey[500],
    paddingTop: 3
  },
  contentWrap: {
    background: grey[100],
    paddingBottom: theme.spacing(2)
  },
  tagLinePreTitle: {
    fontSize: '0.8rem',
    textTransform: 'uppercase',
    color: theme.palette.text.primary
  },
  title: {
    fontSize: '1.5rem',
    paddingBottom: theme.spacing(2)
  },
  image: {
    margin: 'auto',
    width: '80%'
  }
}))

const AboutUs = () => {
  const classes = useStyles()

  return (
    <Grid container justify="center" className={classes.root}>
      <Grid item xs={12} className={classes.logoWrap}>
        <RouterLink to="/" className={classes.logo}>
          Entaxy
          <div className={classes.logoTtagline}>Order from chaos</div>
        </RouterLink>
      </Grid>
      <Grid container justify="center" className={classes.contentWrap}>
        <Grid item xs={11} sm={10} md={8}>
          <Typography variant="caption" component="p" align="center" className={classes.tagLinePreTitle}>
            Our story
          </Typography>
          <Typography variant="h1" align="center" paragraph className={classes.title}>
            About us
          </Typography>
          <Grid container justify="center" className={classes.contentWrap}>
            <Grid item xs={12} md={3}>
              <img
                src={image5} alt="Woman looking at browser"
                className={classes.image}
              />
            </Grid>
            <Grid item xs={12} md={9}>
              <Typography paragraph>
                Entaxy started when a small group of like-minded people got together to discuss their
                personal financial management systems. Between all of us, we’d tried all the apps and
                programs on the market and had the same complaint. They would be helpful… to a point.
                But they would fall short. We’d spend hours setting up this app or that program but
                ultimately we ended up managing our finances in a customized spreadsheet.
                <br />
                <strong>There has to be a better way!</strong>
              </Typography>
            </Grid>
          </Grid>
          <Grid container justify="center" className={classes.contentWrap}>
            <Grid item xs={12} md={8}>
              <Typography paragraph>
                Made up of development, QA, marketing and finance professionals, we were too impatient
                to wait for the market to fill our needs. Instead, we decided to build the right solution
                for ourselves. We loved the solution we were creating and wanted to share it with other
                people, like you. Our mission is simple. We want to empower everyone with the right
                knowledge and tools to gain control of their personal finances without having to
                sacrifice their privacy. It is that last part that drives us. We’re building an app
                that allows you to see all aspects of your financial life clearly but we don’t
                think you should have to share your personal information with us to do that.
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <img
                src={image6} alt="Woman on process"
                className={classes.image}
              />
            </Grid>
          </Grid>
          <Grid container justify="center" className={classes.contentWrap}>
            <Grid item xs={12} md={3}>
              <img
                src={image7} alt="Woman on process"
                className={classes.image}
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography paragraph>
                Entaxy is a full-feature personal finance management tool built utilizing blockchain
                technology for security. Our aim is to create an environment where you can manage
                all aspects of your financial life in one place while providing easy-to-understand
                information that will help users understand their financial life better.
              </Typography>
              <Typography>
                This version of the app is just our starting point. We plan to keep building new
                features and serving up much-needed information to our users. If you have feedback
                or suggestions for us, please visit our Contact page for ways to reach out.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default AboutUs
